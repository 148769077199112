<template>
  <div>
    <el-card class="box-card" v-if="userPermissions.indexOf('trend_log') !== -1">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item
            :title="title"
            name="1">
          <div class="default-table">
            <ApeTable ref="apeTable" :data="logList" :columns="columns" :loading="loadingStaus" :pagingData="pagingData"
                      @switchPaging="switchPaging" highlight-current-row>
              <el-table-column
                  slot="first-column"
                  width="64"
                  label="序号">
                <template slot-scope="scope">
                  <span>{{ offset + scope.$index + 1 }}</span>
                </template>
              </el-table-column>
            </ApeTable>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>
  </div>
</template>

<script>
import ApeTable from "@/components/ApeTable";
import { mapGetters } from "vuex";

export default {
  name: "TrendDataLog",
  components: {
    ApeTable
  },
  props: {
    searchCondition: {
      type: Object,
      default: () => {
        return {
          artist_id: 0,
          platform_code: '',
          ym: '',
        }
      },
    }
  },
  watch: {
    'searchCondition.artist_id'() {
      this.initData()
    },
    'searchCondition.platform_code'() {
      this.initData()
    },
    'searchCondition.ym'() {
      this.initData()
    },
  },
  computed: {
    ...mapGetters(['userPermissions']),
    title() {
      return `操作日志`
    }
  },
  data() {
    return {
      activeNames: [],
      logList: [],
      loadingStaus: false,
      columns: [
        {
          title: '操作时间',
          value: 'created_at',
          width: 120
        },
        {
          title: '红人昵称',
          value: 'nickname',
          width: 100
        },
        {
          title: '分组',
          value: 'group_name',
          width: 100
        },
        {
          title: '平台',
          value: 'platform_name',
          width: 100
        },
        {
          title: '日志内容',
          value: 'message',
          width: 640
        }
      ],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      // 分页的offset,序号列使用
      offset: 0,
    }
  },
  methods: {
    // 搜索
    initData() {
      this.getLogList()
    },
    // 切换页码操作
    async switchPaging() {
      await this.getLogList()
    },
    async getLogList() {
      this.loadingStaus = true
      this.logList = []
      if (this.activeNames.length > 0 && this.searchCondition.artist_id && this.searchCondition.ym && this.searchCondition.platform_code) {
        let pagingInfo = this.$refs['apeTable'].getPagingInfo()
        let searchCondition = this.searchCondition
        // 合并
        Object.assign(searchCondition, pagingInfo)
        let { list, pages } = await this.$api.getTrendDataLog(searchCondition)
        this.$nextTick(() => {
          this.logList = list
        })
        this.pagingData.total = pages.total
        this.offset = pages.offset
        this.loadingStaus = false
      }
    },
    handleChange(val) {
      if (this.activeNames.length > 0)
        this.getLogList(val)
    },
  }
}
</script>

<style scoped>

</style>
